import React, { useEffect, useState, ReactElement } from "react";
import { LPTableSection } from "../../api/Types";
import LandingPageSectionWrapper from "../common/LandingPageSectionWrapper";
import DynamicCallToAction from "../common/DynamicCallToAction";

type Props = LPTableSection;

const MobileBreakpoint = 992;

// catches strings starting with the _underscore character
const EmptyHeaderPattern = /_.*/g;

// [1,2,2,3] => '1fr 2fr 2fr 3fr'
const toTemplateColumns = (widths, responsiveStyle): string =>
  widths.map((width) => (width === 0 ? "auto" : `${width}fr`)).join(" ");

// { _image: '', h_1: '', h_2: '', _action: '' } => ['', 'h_1', 'h_2', '']
const toTableLabels = (aDataObj: {
  [key: string]: string;
}): ReadonlyArray<string> =>
  Object.keys(aDataObj).map((headerKey) =>
    // string matching regex are hidden labels
    headerKey.match(EmptyHeaderPattern) ? "" : headerKey,
  );

const isVertical = (layout: "vertical" | "horizontal"): boolean =>
  layout === "vertical";
const isHorizontal = (layout: "vertical" | "horizontal"): boolean =>
  layout === "horizontal";

const getTemplateColumns = (
  layout,
  labelsCount,
  widths,
  responsiveStyle,
): string => {
  return isHorizontal(layout)
    ? `repeat(${labelsCount}, 1fr)`
    : widths?.length
      ? toTemplateColumns(widths, responsiveStyle)
      : "auto";
};

const getTemplateRows = (layout, labelsCount): string => {
  if (isVertical(layout)) return "auto";
  return `repeat(${labelsCount}, auto)`;
};

/**
 * Lays out labels to their respective position inside the grid layout.
 */
const layoutLabels = (layout, tableLabels, labelsCount): ReactElement =>
  tableLabels.map((label, index) => {
    const style = isVertical(layout)
      ? { gridRow: index + 1 }
      : { gridColumn: index + 1 };

    const key = `table-label-${index}`;

    const labelComponent = label ? (
      <h3 dangerouslySetInnerHTML={{ __html: label }} />
    ) : null;
    const labelSpecificClass =
      index === labelsCount - 1 ? "table-label-last" : "";

    return (
      <div
        key={key}
        style={style}
        className={`table-label ${labelSpecificClass}`}
      >
        {labelComponent}
      </div>
    );
  });

/**
 * Lays out properties of every data entry inside the grid layout.
 */
const layoutTableEntry = (
  entry: { [key: string]: string },
  entryIndex: number,
  layout,
  labelsCount,
  entriesCount,
): Array<ReactElement> => {
  const entryPositionBaseInGrid = isVertical(layout) ? "gridColumn" : "gridRow";
  const entryPropertiesPositionBaseInGrid = isVertical(layout)
    ? "gridRow"
    : "gridColumn"; // inverse of entryPositionBaseInGrid
  /*
    base position in grid system is 1 and entries
    need to be displayed after the labels row/column (+1)
    therefore, we need to add 2 to the entry index
  */
  const trueEntryGridPositionIndex = entryIndex + 2;
  return Object.keys(entry).map((key, entryPropertyIndex) => {
    const propertySpecificClassNames = [
      `table-entry entry-${entryIndex} ${entryIndex % 2 === 0 ? "entry-even" : "entry-odd"}`,
      `entry-property-${entryPropertyIndex}`,
    ];
    // when layout is vertical...
    if (entryPropertyIndex === 0 && isVertical(layout)) {
      // do not ajust display for first property of entry.
      propertySpecificClassNames.push("entry-first-property");
    }
    if (entryPropertyIndex === labelsCount - 1) {
      // hide border for last property of entry.
      propertySpecificClassNames.push("entry-last-property");
    }
    // hide border for every property of the last entry when layout is horizontal.
    if (entryIndex === entriesCount - 1 && isHorizontal(layout)) {
      propertySpecificClassNames.push("entry-last-entry-property");
    }

    return (
      <div
        key={`entry-${entryIndex}-prop-${entryPropertyIndex}`}
        className={propertySpecificClassNames.join(" ")}
        style={{
          [entryPositionBaseInGrid]: trueEntryGridPositionIndex,
          [entryPropertiesPositionBaseInGrid]: entryPropertyIndex + 1,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: entry[key] }} />
      </div>
    );
  });
};

const layoutEntryForMobileDisplay = (entry, entryIndex): ReactElement => {
  return (
    <div key={`table-entry-card-${entryIndex}`} className="table-entry-card">
      {Object.keys(entry).map((labelKey, entryPropertyIndex) => {
        const labelKeyVisible = labelKey.match(EmptyHeaderPattern)
          ? false
          : true;
        const propertyKey = `entry-card-${entryIndex}-prop-${entryPropertyIndex}`;
        const value = entry[labelKey];
        if (labelKeyVisible) {
          return (
            <div key={propertyKey} className="entry-card-label-value">
              <h3 dangerouslySetInnerHTML={{ __html: labelKey }} />
              <div dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          );
        }
        return (
          <div
            key={propertyKey}
            className="entry-card-value-no-label"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      })}
    </div>
  );
};

const LandingPageTable: React.FunctionComponent<Props> = ({
  defaultClassName,
  data,
  subtitle,
  description,
  tableHeader,
  tableFooter,
  cta,
  titleTextAlign,
  style,
  tableStyle,
}) => {
  if (!data) return null;

  const [shouldShowMobile, setShouldShowMobile] = useState(
    window.innerWidth < MobileBreakpoint &&
    tableStyle.responsiveStyle !== "scroll",
  );

  const {
    columnWidths,
    layout = "vertical",
    borderRow = false,
    borderColumn = false,
  } = tableStyle;

  const {
    headerBackgroundColor,
    responsiveStyle,
    borderStyle,
    alterningRowBackgroundColor,
    innerCellTextAlignment,
  } = tableStyle;

  const onResize = (): void =>
    setShouldShowMobile(
      window.innerWidth < MobileBreakpoint &&
      tableStyle.responsiveStyle !== "scroll",
    );

  // componentDidMount
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const tableLabels = toTableLabels(data[0]);
  const labelsCount = tableLabels.length;

  const classNames = [`table-layout-${layout}`];
  if (borderRow) classNames.push("table-with-border-row");
  if (borderColumn) classNames.push("table-with-border-column");
  if (headerBackgroundColor)
    classNames.push(`table-with-${headerBackgroundColor}-header-bg`);
  if (borderStyle) classNames.push(`table-with-${borderStyle}-border`);
  if (alterningRowBackgroundColor)
    classNames.push("table-with-alterning-row-background-color");
  if (innerCellTextAlignment)
    classNames.push(
      `table-with-inner-cell-text-align-${innerCellTextAlignment}`,
    );

  if (!shouldShowMobile) {
    classNames.push("table-section-grid");
  } else {
    classNames.push("table-section-cards");
  }

  return (
    <LandingPageSectionWrapper
      style={style}
      defaultClassName={defaultClassName}
      cssNamespace="table-section"
    >
      <div className="table-section__headers-container">
        <h2 className={`table-section__subtitle`}>{subtitle}</h2>
        {tableHeader && (
          <h3 className={`table-section-header`}>
            {tableHeader}
          </h3>
        )}
        <p className={`table-section__description`} >{description}</p>
      </div>
      <div
        className={
          responsiveStyle === "scroll" ? "table-responsive-scroll" : ""
        }
      >
        {!shouldShowMobile ? (
          <div
            className={classNames.join(" ")}
            style={{
              gridTemplateColumns: getTemplateColumns(
                layout,
                labelsCount,
                columnWidths,
                responsiveStyle,
              ),
              gridTemplateRows: getTemplateRows(layout, labelsCount),
            }}
          >
            {layoutLabels(layout, tableLabels, labelsCount)}
            {data.map((entry, index) =>
              layoutTableEntry(entry, index, layout, labelsCount, data.length),
            )}
          </div>
        ) : (
          <div className={classNames.join(" ")}>
            {data.map((entry, index) =>
              layoutEntryForMobileDisplay(entry, index),
            )}
          </div>
        )}
      </div>
      {tableFooter && <p className="table-section-footer">{tableFooter}</p>}
      {cta && <DynamicCallToAction cta={cta} />}
    </LandingPageSectionWrapper>
  );
};

export default LandingPageTable;
